import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

export interface PdcDateOptions {
  readonly format?: string;
  readonly precision?: 'm' | 's' | 'ms';
}

@Pipe({
  name: 'pdcDate',
  standalone: true
})
export class PdcDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: string, options?: PdcDateOptions): string {
    options = options || {};
    if (options.precision === 'm') {
      return this.datePipe.transform(value, 'dd.MM.yyyy, HH:mm');
    }
    if (options.precision === 's') {
      return this.datePipe.transform(value, 'dd.MM.yyyy, HH:mm:ss');
    }
    if (options.precision === 'ms') {
      return this.datePipe.transform(value, 'dd.MM.yyyy, HH:mm:ss.SSS');
    }
    if (options.format) {
      return this.datePipe.transform(value, options.format);
    }
    return this.datePipe.transform(value);
  }
}
